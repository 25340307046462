body{
  margin: 0;
  padding: 0;
  
  font-family: Arial, Helvetica, sans-serif;

}


.footer {
  background-color: #3b3b3b;
  color: white;
  padding: 10px 10px;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  color: white;
  flex-wrap: wrap; /* Allows items to wrap on smaller screens */
}

.footer-about,
.footer-contact,
.footer-social {
  flex: 1;
 
  min-width: 250px; /* Ensures minimum width for each section */
}

.footer h3 {
  font-size: 20px;
 
  color: white;
}

.footer p,
.footer a {
  font-size: 16px;
  color: white;
  text-decoration: none;
  line-height: 1.6;
}

.footer a:hover {
  text-decoration: underline;
}

.footer-social a {
  display: block;
  margin-bottom: 0px;
}

.footer-copyright {
  
  font-size: 14px;
  color: #999;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    text-align: center;
  }


}


.page-contact {
  margin-bottom: 40px;
  font-size: 18px;
  color: #333;
  text-align: center;
}
.page-contact p {
  margin: 10px 0;
  font-size: 18px;
  color: #454545;
}
.page-contact a {
  color: #383838;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}




.footer-contact {
  margin-bottom: 40px;
  font-size: 18px;
  color: #333;
  text-align: center;
}

.footer-contact p {
  margin: 10px 0;
  font-size: 18px;
  color: #ffffff;
}

.footer-contact a {
  color: #ffffff;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}

.footer-contact a:hover {
  color: #333;
  text-decoration: underline;
}

.contact-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 30px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  animation: fadeIn 1s ease-in-out;
}

.contact-form label {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
  font-weight: 500;
}

.contact-form input,
.contact-form textarea {
  width: 80%;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #333;
  background-color: #fff;
  outline: none;
}

.contact-form button {
  background-color: #333;
  color: #fff;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.contact-form button:hover {
  background-color: #333;
  transform: translateY(-2px);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}



.appointment-container {
  padding: 40px;
  background-color: #f9f9f9;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.appointmentheading {
  font-size: 36px;
  color: #333;
  margin-bottom: 30px;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
}

.shop-hours {
  background: #fff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 350px;
  margin-bottom: 20px;
}

.shop-hours h2 {
  font-size: 24px;
  color: #444;
  margin-bottom: 20px;
  font-weight: 600;
  text-transform: uppercase;
}

.shop-hours ul {
  list-style: none;
  padding: 0;
  font-size: 18px;
  color: #555;
}

.shop-hours li {
  margin: 10px 0;
  padding: 10px;
  background: #e9ecef;
  border-radius: 8px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.shop-hours li:hover {
  background-color: #d6d6d6;
  color: #302e2e;
}

.appointment-form {
  width: 100%;
  max-width: 850px;
 
  background: #fff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
}

.form-group {
  margin-bottom: 25px;
  text-align: left;
}

.appointment-form label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: #333;
}

.appointment-form input,
.appointment-form select,
.appointment-form textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.appointment-form input:focus,
.appointment-form select:focus,
.appointment-form textarea:focus {
  border-color: #676767;
  outline: none;
}

.appointment-form textarea {
  resize: vertical;
}

.appointment-form button {
  background-color: #656565;
  color: #fff;
  border: none;
  padding: 14px 24px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.appointment-form button:hover {
  background-color: #434343;
  transform: scale(1.02);
}

@media (min-width: 768px) {
  .appointment-container {
    flex-direction: row;
    justify-content: space-between;
    gap: 40px;
  }

  .appointment-form {
    width: 60%;
  }

  .shop-hours {
    width: 35%;
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .appointment-form {
    width: 100%;
  }

  .shop-hours h2 {
    font-size: 22px;
  }

  .appointment-form button {
    width: 100%;
    padding: 12px;
    font-size: 16px;
  }

  .appointmentheading {
    font-size: 28px;
  }
}







/* General Styles */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f7f7f7;
  margin: 0;
  padding: 0;
  color: #333;
}

h1, h2 {
  font-weight: bold;
  text-align: center;
  color: #2c3e50;
  margin-bottom: 1rem;
}

h1 {
  font-size: 2.5rem;
  margin-top: 1.5rem;
}

h2 {
  font-size: 2rem;
}


/* General styles */
body {
  font-family: 'Roboto', sans-serif;
  background-color: #f8f9fa;
  margin: 0;
  padding: 0;
}

h2 {
  text-align: center;
  font-size: 2.5rem;
  color: #333;
  margin: 2rem 0;
  font-weight: bold;
}

/* Gallery Section */
.gallery {
  padding: 2rem;
  background-color: #ffffff;
  margin: 2rem auto;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
  padding: 0 1rem;
}

.gallery-item {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 200px;
}

.gallery-item img {
  width: 100%;
  height: 200px;
  display: block;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.gallery-item:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.gallery-item:hover img {
  transform: scale(1.1);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.gallery-item {
  animation: fadeIn 0.5s ease-in;
}

/* Custom Scrollbar */
.gallery-grid {
  max-height: 80vh;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #aaa #e0e0e0;
}

.gallery-grid::-webkit-scrollbar {
  width: 10px;
}

.gallery-grid::-webkit-scrollbar-track {
  background: #e0e0e0;
  border-radius: 10px;
}

.gallery-grid::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 2px solid #e0e0e0;
}

.gallery-grid::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.car-brands-section {
  padding: 0;
  background-color: #ffffff;
  text-align: center;
}

.car-brands-container {
  max-width: 1400px;
  margin: 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.car-brands-heading {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 1rem;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.car-brands-subheading {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  color: #555;
}

.car-brands-logos {
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  background-color: #333;
  border-radius: 5px;
}

.car-brand-logo {
  flex: 1 1 150px; /* Adjust width as needed */
  margin:0px 0px;
  max-width: 150px;
  
}

.car-brand-image {
  max-width: 100px;
  height: 100px;
  margin: 20px;
  border-radius: 10px;
}



/* General Styles */
.testimonials {
  padding: 60px 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.testimonials h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 32px;
  margin-bottom: 40px;
  color: #2c3e50;
  position: relative;
  display: inline-block;
}

.testimonials h2::after {
  content: '';
  width: 50px;
  height: 3px;
  background-color: #2c2c2c;
  display: block;
  margin: 10px auto;
  border-radius: 2px;
}

/* Testimonial Cards Container */
.testimonial-cards-container {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  padding: 20px 0;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: #2c2c2c #ffffff;
  height: 250px;
}

/* Custom Scrollbar */
.testimonial-cards-container::-webkit-scrollbar {
  height: 12px;
}

.testimonial-cards-container::-webkit-scrollbar-track {
  background: #f0f8ff;
  border-radius: 10px;
}

.testimonial-cards-container::-webkit-scrollbar-thumb {
  background: #2c2c2c;
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

.testimonial-cards-container::-webkit-scrollbar-thumb:hover {
  background: #2c2c2c;
}

/* Testimonial Card Styles */
.testimonial-card {
  flex: 0 0 calc(100% / 3 - 40px);
  background: linear-gradient(135deg, #ffffff, #d9dee1);
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: left;
  width: 100px;
  max-width: calc(100% / 3 - 40px);
  scroll-snap-align: start;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  position: relative;
}

.testimonial-card:hover {
  transform: scale(1.05) translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.testimonial-card img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 15px;
  border: 3px solid #2c2c2c;
}

.reviewimage {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-around;
}

.testimonial-card p {
  font-size: 16px;
  line-height: 1.6;
  color: #34495e;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 10px;
}

.testimonial-card h4 {
  font-size: 18px;
  color: #2c3e50;
  font-family: 'Poppins', sans-serif;
  border-bottom: 1px solid #2c2c2c;
  padding-bottom: 5px;
  margin-bottom: 15px;
}

/* Animation */
.testimonial-card {
  opacity: 0;
  animation: fadeInUp 0.8s ease forwards;
  animation-delay: calc(0.1s * var(--delay));
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.testimonial-cards-container .testimonial-card:nth-child(1) {
  --delay: 1;
}

.testimonial-cards-container .testimonial-card:nth-child(2) {
  --delay: 2;
}

.testimonial-cards-container .testimonial-card:nth-child(3) {
  --delay: 3;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .testimonial-cards-container {
    gap: 10px;
  }

  .testimonial-card {
    flex: 0 0 calc(100% / 1.5);
    max-width: calc(100% / 1.5);
  }
}

@media (max-width: 480px) {
  .testimonial-card {
    flex: 0 0 calc(100% - 40px);
    max-width: calc(100% - 40px);
  }

  .testimonial-card img {
    width: 50px;
    height: 50px;
  }

  .testimonial-card h4 {
    font-size: 16px;
  }

  .testimonial-card p {
    font-size: 14px;
  }
}
